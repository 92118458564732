<template>
  <div
    v-if="!header"
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToMain"
  >
    {{ $t('DStudioHeadNav1') }}
  </div>
  <div
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToWithCreate('#why-us')"
  >
    {{ $t('DStudioHeadNav2') }}
  </div>
  <div
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToWithCreate('#services')"
  >
    {{ $t('DStudioHeadNav3') }}
  </div>
  <div
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToWithCreate('#projects')"
  >
    {{ $t('DStudioHeadNav4') }}
  </div>
  <div
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToWithCreate('#reviews')"
  >
    {{ $t('DStudioHeadNav5') }}
  </div>
  <div
    :class="[specialClass, {white: isWhiteClass}]"
    @click="goToWithCreate('#cost-project')"
  >
    {{ $t('DStudioHeadNav6') }}
  </div>
</template>
<script lang="ts" setup>
import {goTo} from '~/helpers/baseFunction';

interface IProps {
  specialClass?: 'right-text' | 'footer-text';
  isWhiteClass?: boolean;
  header?: boolean;
  isCreatePage?: boolean;
}

const goToMain = async () => {
  await navigateTo('/');
}

const route = useRoute();

const goToWithCreate = async (goto: string) => {
  let path = route.fullPath.includes('d-studio') ? '/d-studio' : '/design-studio';

  if (route.fullPath.includes('create')) {
    await navigateTo(path + goto);
  }

  goTo(goto);
}

const props = withDefaults(defineProps<IProps>(), {
  specialClass: 'right-text',
  isWhiteClass: false,
  header: false,
  isCreatePage: false,
});
</script>
<style lang="scss" scoped>
.right-text {
  cursor: pointer;
  transition: all 0.2s linear;

  color: var(--var-black-100);


  &:hover {
    color: var(--var-blue-100);
  }

  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.28px;
}
.white {
  font-weight: 400;
  color: var(--var-white-000);
}
.footer-text {
  @include launcher-p3;
  cursor: pointer;
}
</style>
