<template>
  <header class="header__wrapper" id="header">
    <ui-header
      class="header q-mt-lg"
      :class="[{white: isWhiteClass}]"
    >
      <div class="container">
        <ui-link
          class="logo"
          aria-label="Home"
          :to="route.path.includes('design-studio')?`/${locale}/design-studio`:`/${locale}/d-studio`"
        >
          <DesignStudioLogo/>
        </ui-link>
        <div class="right" :class="{arabic}">

          <DesignStudioDSNavigationComponent
            v-if="isNeedHidden"
            :isWhiteClass="!isWhiteClass"
            :header="true"
          />

          <ui-fab
            v-if="listLang.length > 1 && !isDflat"
            class="change-lang"
            :options="listLang"
            @select="onSelectLang"
            :btnCloseColor="
              !isWhiteClass
                ? 'var(--var-white-000)'
                : 'var(--var-black-100)'
            "
          >
            <IconLangWhite v-if="!isWhiteClass" />
            <IconLang v-else />
          </ui-fab>
          <ui-button
            color="primary-offer"
            :size="ButtonSizeType.small"
            @click="orderDesign"
            v-if="isNeedHidden"
          >
            {{ $t('orderDesign') }}
          </ui-button>
          <div v-if="!isLoggedIn && route.path.includes('d-studio')" class="login" @click="authHandler">
            <IconLoginWhite v-if="!isWhiteClass" />
            <IconLogin v-else />
            {{ $t('login') }}
          </div>
          <div v-if="isLoggedIn && route.path.includes('d-studio')" class="user-block">
            <ui-link>
              <IconUser v-if="isWhiteClass" class="h-icon-pr" />
              <IconUserWhite v-else class="h-icon-pr" />
              <div class="username">
                {{
                  user.email.length > 6
                    ? user.email.slice(0, 6) + '...'
                    : user.email
                }}
              </div>
            </ui-link>
          </div>
        </div>
      </div>
    </ui-header>
    <ui-header
      class="header mobile-header"
      :class="[{white: isWhiteClass}]"
    >
      <div id="sidemenu">
        <button
          v-if="changeLanguageInMobileModal === false && isNeedHidden"
          class="sidemenu__btn"
          :class="[{active: navOpen}, {white: isWhiteClass}]"
          @click="navOpen = !navOpen"
        >
          <span class="top" />
          <span class="mid" />
          <span class="bottom" />
        </button>
        <transition name="translateX">
          <nav
            v-show="navOpen"
            :style="{
            right: rightDir ? '0' : 'auto',
            left: rightDir ? 'auto' : '0',
          }"
          >
            <div class="sidemenu-header-main">
              <div
                v-if="!changeLanguageInMobileModal"
                class="title-mobile-menu-block_logo"
              >
                <ui-link
                  class="logo"
                  to="/"
                  aria-label="Home"
                  @click="forWhomShowListVar = false"
                >
                  <DFLogo width="50" height="27" />
                  <div class="title">{{ $t(title) }}</div>
                </ui-link>
              </div>
              <div
                v-if="changeLanguageInMobileModal"
                class="title-mobile-menu-block_lang"
                dir="ltr"
              >
                <div
                  class="mobile-lang-back-button"
                  @click="changeLanguageInMobileModal = false"
                >
                  <IconArrowToTopGray />
                </div>
                <div class="mobile-lang-text">
                  {{ $t('choseLang') }}
                </div>
              </div>
              <div
                v-if="!changeLanguageInMobileModal && listLang.length > 1 && !isDflat"
                class="change-language-mobile-block"
                :class="{
                'change-language-mobile-block--right': rightDir,
              }"
                @click="
                changeLanguageInMobileModal =
                  !changeLanguageInMobileModal
              "
              >
                <IconLang />
              </div>
            </div>
            <div class="sidemenu__wrapper">
              <ul
                v-if="changeLanguageInMobileModal && !isDflat"
                class="sidemenu__list sidemenu__list__language-menu"
                :class="{'right-list': rightDir}"
              >
                <li class="sidemenu__item">
                  <div
                    class="sidemenu__list__language-menu__item"
                    @click="
                      onSelectLang('en');
                      navOpen = false;
                      changeLanguageInMobileModal = false;
                    "
                  >
                    {{ $t('English') }}
                  </div>
                </li>
                <li class="sidemenu__item">
                  <div
                    class="sidemenu__list__language-menu__item"
                    @click="
                      onSelectLang('ru');
                      navOpen = false;
                      changeLanguageInMobileModal = false;
                    "
                  >
                    {{ $t('Русский') }}
                  </div>
                </li>
              </ul>

              <ul
                v-if="!changeLanguageInMobileModal"
                class="sidemenu__list"
                :class="{'right-list': rightDir}"
              >
                <li class="sidemenu__item">
                  <a
                    :class="{active: loc.includes('/gallery')}"
                    @click="goToWithClose('#why-us')"
                  >
                    {{ $t('DStudioHeadNav2') }}
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a
                    :class="{active: loc.includes('/gallery')}"
                    @click="goToWithClose('#services')"
                  >
                    {{ $t('DStudioHeadNav3') }}
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a
                    :class="{active: loc.includes('/gallery')}"
                    @click="goToWithClose('#projects')"
                  >
                    {{ $t('DStudioHeadNav4') }}
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a
                    :class="{active: loc.includes('/gallery')}"
                    @click="goToWithClose('#reviews')"
                  >
                    {{ $t('DStudioHeadNav5') }}
                  </a>
                </li>
                <li class="sidemenu__item">
                  <a
                    :class="{active: loc.includes('/gallery')}"
                    @click="goToWithClose('#cost-project')"
                  >
                    {{ $t('DStudioHeadNav6') }}
                  </a>
                </li>

                <ui-button
                  color="primary-offer"
                  :size="ButtonSizeType.b_m"
                  @click="orderDesign"
                  v-if="isNeedHidden"
                  class="showWhenLess720"
                  style="width: 268px; height: 44px"
                >
                  {{ $t('orderDesign') }}
                </ui-button>

              </ul>
            </div>
          </nav>
        </transition>
        <div
          v-if="navOpen"
          class="background-opacity"
          @click="
          navOpen = false;
          changeLanguageInMobileModal = false;
          "
        />
      </div>
      <nuxt-link
        v-if="
        loc === `/${locale}/help-center` ||
        (!loc.includes('/user') && !loc.includes('/help-center'))
      "
        class="logo"
        :to="route.path.includes('design-studio')?`/${locale}/design-studio`:`/${locale}/d-studio`"
        aria-label="Home"
      >
        <DesignStudioLogo/>
      </nuxt-link>

      <ui-link
        v-else-if="loc.includes('/help-center')"
        class="logo"
        to="/help-center"
      >
        <div class="title">
          {{ $t('helpCenter') }}
        </div>
      </ui-link>

      <div class="right">
        <ui-button
          color="primary-offer"
          :size="ButtonSizeType.small"
          @click="orderDesign"
          v-if="isNeedHidden"
          class="hiddenWhen720"
        >
          {{ $t('orderDesign') }}
        </ui-button>

      </div>
    </ui-header>
  </header>
</template>
<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue';
import {useRoute} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {useRuntimeConfig} from '#imports';
import {useUserStore} from '~/modules/User/user.store';
import IconLangWhite from '~/assets/svg/lang-white.svg';
import {useBaseStore} from '~/modules/BaseModule/base.store';
import IconLang from '~/assets/svg/lang.svg';
import IconArrowToTopGray from '~/assets/svg/arrowtotopgray.svg';
import {useDirectionStore} from '~/store/direction.store';
import IconLoginWhite from '~/assets/svg/login-white.svg';
import IconLogin from '~/assets/svg/login.svg';
import IconUser from '~/assets/svg/user.svg';
import IconUserWhite from '~/assets/svg/user.white.svg';
import {ButtonSizeType} from '~/interfaces/ui/button';
import {useDesignStudioStore} from '~/store/design-studio.store';
import {goTo} from '~/helpers/baseFunction';

const direction = useDirectionStore();
const base = useBaseStore();
const user = useUserStore();
const designStudio = useDesignStudioStore();
const route = useRoute();
const runtimeConfig = useRuntimeConfig();
const rightDir = computed(() => direction.isRight);
const isLoggedIn = computed(() => user.isLoggedIn);
const listLang = [{label: 'Русский', value: 'ru'}, {label: 'English', value: 'en'}];
const {locale} = useI18n();
const arabic = computed(() => direction.$state.isRight);
const emits = defineEmits(['designRequest']);

let isMount = false;
const isDflat =
  runtimeConfig.public.vueAppBaseUrl.includes('d-flat.ru');

const loc = computed(() => {
  return route.path;
});

const goToWithClose = (goto: string) => {
  navOpen.value = false;
  goTo(goto);
}

const authHandler = () => {
  navOpen.value = false;
  designStudio.btnCreateHandlerForLanding();
};

const orderDesign = () => {
  if (route.path.includes('d-studio')) {
    authHandler();
  } else {
    emits('designRequest');
  }
}

if (!route.fullPath.includes('ru') && !route.fullPath.includes('eu')) {
  locale.value = 'en';
}

function onSelectLang(option: string) {
  locale.value = option;
}

const title = runtimeConfig.public.vueAppTitle;

const isNeedHidden = computed(() => loc.value.split('/').length <= 3);

const navOpen = ref(false);
const changeLanguageInMobileModal = ref(false);

const isWhite = ref(true);
const isWhiteClass = computed(() => true);

onMounted(() => {
  isMount = true;
});

watchEffect(() => {
  if (typeof document !== 'undefined') {
    if (navOpen.value) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }
});
</script>
<style lang="scss" scoped>
.header__wrapper {
  .white {
    background-color: var(--var-white-000);
    animation: setBgAnimation alternate 0.5s;
  }
}
@keyframes setBgAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.header {
  width: 100vw;
  min-width: 100vw;
  height: 92px;
  max-height: 92px;
  min-height: 92px;
  z-index: 100000;

  .container {
    max-width: 1252px;
    color: $dark;
    display: flex;
    justify-content: space-between;

    .title {
      color: var(--var-white-000);
    }

    .number {
      @include headline-h4;
      color: var(--var-blue-50);
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin-right: $m-2;
        }
      }
    }
  }
}
.header__wrapper {
  position: relative;
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;

  &_static {
    margin-bottom: $mt-5;
  }
}

.container {
  max-width: unset;

  @media (max-width: 1200px) {
    padding-left: $p-3;
    padding-right: $p-3;
  }
}
.ui-header {
  color: $dark;
  display: flex;
  justify-content: space-between;
  .menu {
    align-items: center;
    display: flex;
    gap: 24px;
    font-weight: 600;
  }

  .right {
    display: flex;
    align-items: center;
    &.arabic {
      margin-left: 0;
    }
    column-gap: $g-4;

    .h-lang {
      .q-icon {
        font-size: 18px;
      }
    }

    .login {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;
      //margin-left: 16px;
    }

    .lang-btn-groupe-f {
      width: 120px;
      background: $primary;
      border-radius: 8px;
      position: relative;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        /* Прозрачные границы */
        border-bottom: 10px solid #2491e7;
        /* Добавляем треугольник */
        right: 50px;
        top: -20px;
      }

      .q-btn {
        width: 110px;
        margin: 0 auto;
        padding: 6px 12px;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }

    @media (max-width: $md) {
      column-gap: 16px;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    margin-right: 4px;
    gap: $g-4;
    .title {
      @include title-fw-600-fs-22-black-100;
      color: white;
    }
  }
  &.white {
    .sidemenu {
      &__btn {
        span {
          background: var(--var-black-100) !important;
        }
      }
    }
    .logo {
      .title {
        color: var(--var-black-100);
      }
    }
    color: white !important;

    .title {
      color: var(--var-white-100);
    }

    span {
      color: white !important;
    }
  }
}
#sidemenu {
  display: none;
  .background-opacity {
    display: none;
  }
}

.mobile-header {
  display: none;
}

@media (max-width: $lg) {
  .header {
    display: none;
  }
  .mobile-header {
    display: flex;
  }
  .sidemenu__top {
    margin-top: -$mt-10;
    margin-bottom: $mb-5;
    .active {
      top: 5px !important;
    }
  }
  #sidemenu {
    display: block;
    //margin-left: 5px;
    nav {
      width: 300px;
      height: 100%;
      // height: calc(100% - #{$headerHeight} - #{$footerHeight});
      background: #ffffff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      // box-shadow: 2px 0 3px$grey-6;
      overflow-y: auto;
      .sidemenu-header-main {
        display: flex;
      }
      .change-language-mobile-block {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 39px;
        left: 85%;
        &.change-language-mobile-block--right {
          left: auto;
          right: 85%;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .title-mobile-menu-block_logo {
        position: absolute;
        left: 24%;
        top: 34px;
        width: 160px;
        .mobile-menu-title {
          color: #3d424a;
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        }
      }
      .title-mobile-menu-block_lang {
        position: absolute;
        left: 22px;
        top: 38px;
        //width: 175px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        color: #3d424a;
        display: flex;
        .mobile-lang-back-button {
          rotate: -90deg;
          margin-right: 40px;
          img {
            width: 14.4px;
            height: 8.4px;
          }
        }
      }
    }
    .background-opacity {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(32, 48, 64, 0.75);
      display: block;
      z-index: 98;
    }
    .sidemenu {
      &__btn {
        display: block;
        width: 50px;
        height: 50px;
        background: inherit;
        border: none;
        position: relative;
        z-index: 10000;
        appearance: none;
        cursor: pointer;
        outline: none;
        span {
          display: block;
          background: #3d424a;
          width: 20px;
          height: 2px;
          margin: auto;
          position: absolute;
          top: 10px;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.4s ease;

          &.top {
            width: 10px;
            right: 10px;
            transform: translateY(-8px);
          }

          &.bottom {
            width: 10px;
            left: 10px;
            transform: translateY(8px);
          }
        }
        &.active {
          //.sidemenu__btn {
          span {
            background: #839aaf !important;
            //}
          }
          .top {
            width: 20px;
            right: 0;
            transform: rotate(-45deg);
          }
          .mid {
            transform: translateX(-20px) rotate(360deg);
            opacity: 0;
          }
          .bottom {
            width: 20px;
            left: 0;
            transform: rotate(45deg);
          }
        }
        &.white {
          span {
            background: #ffffff;
          }
        }
      }
      &__wrapper {
        padding-top: 104px;
      }
      &__list {
        list-style: none;
        padding: 0 0 0 16px;
        margin: 0;
        &.right-list {
          padding: 0 16px 0 0;
        }
      }
      &__item {
        a {
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;
          &:hover {
            background: #ffffff;
            color: var(--var-blue-100);
          }
        }
        a.active {
          background: #ffffff;
          color: var(--var-blue-100);
        }
        .help-center-link {
          margin-bottom: 46px;
        }
        .sidemenu__list__language-menu__item {
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;
          &:hover {
            background: #ffffff;
            color: var(--var-blue-100);
          }
        }
        .sidemenu__list__language-menu__item.active {
          background: #ffffff;
          color: var(--var-blue-100);
        }
        .sidemenu__item__button {
          .login-button-in-burger-menu {
            display: flex;
            justify-content: center;
            background: var(--var-blue-100);
            width: 92%;
            padding: 10px 32px 10px 24px;
            border-radius: 8px;
            &__icon {
              margin-right: 14.5px;
            }
            &__text {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              letter-spacing: 0.02em;
              color: #ffffff;
            }
          }
        }
        &__details {
          border: none;
          text-decoration: none;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #3d424a;
          display: block;
          transition: 0.4s ease;
          margin-bottom: 36px;
          list-style: none;
          outline: none;
          user-select: none;
          summary {
            .summary-icon-close {
              display: block;
              position: relative;
              left: 85%;
              top: -8px;
              height: 9px;
              width: 15px;
            }
            .summary-icon-open {
              display: none;
              position: relative;
              left: 85%;
              top: -8px;
              height: 9px;
              width: 15px;
            }
            #side-menu-customers-title {
              color: #3d424a !important;
            }
          }
          a {
            margin: 28px 32px;
          }
          .for-designers {
            margin: 20px 32px 28px 32px;
          }
        }
        &__details[open] {
          summary {
            .summary-icon-close {
              display: none;
            }
            .summary-icon-open {
              display: block;
            }
          }
        }
        details summary::-webkit-details-marker {
          display: none;
        }
        details:focus,
        summary:focus {
          outline: 0;
          outline: none;
        }
      }
    }
  }
  .h-menu {
    display: none !important;
  }
  .h-wrp {
    justify-content: space-between;

    .right {
      margin-left: 0;

      .user-block {
        .username {
          display: none;
        }
      }
    }
  }

  .translateX-enter {
    transform: translateX(-200px);
    opacity: 0;
  }
  .translateX-enter-active,
  .translateX-leave-active {
    transform-origin: top left 0;
    transition: 0.2s ease;
  }
  .translateX-leave-to {
    transform: translateX(-200px);
    opacity: 0;
  }
}
.user-block {
  padding: 0px;
  a {
    display: flex;
    gap: $g-2;
    align-items: center;
    .h-icon-pr {
      margin-right: 0px;
    }
  }
}

.hiddenWhen720 {
  display: block;

  @media (max-width: 719px) {
  display: none;
  }
}

.showWhenLess720 {
  display: none;

  @media (max-width: 719px) {
    display: block;
  }
}
</style>
